import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import CardGroup from "react-bootstrap/CardGroup";
import Card from "react-bootstrap/Card";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        homeImage: file(relativePath: { eq: "home_armor.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout title="Cerco Corporation" titleTemplate={"%s"}>
        <Container className="home">
          <Row>
            <Col>
              <Carousel indicators={false} controls={false}>
                <Carousel.Item>
                  <Img
                    fluid={data.homeImage.childImageSharp.fluid}
                    alt="Armor"
                  />
                  <CardGroup className="d-none d-lg-flex">
                    <Card body className="blue">
                      <Card.Body className="d-flex align-items-center">
                        <Link to="/structural-components">
                          <span className="box">1</span>
                          <span>Structural Components</span>
                        </Link>
                      </Card.Body>
                    </Card>
                    <Card body className="green">
                      <Link to="/grinding-media">
                        <span className="box">2</span>
                        <span>Ceramic Grinding Media</span>
                      </Link>
                    </Card>
                    <Card body className="yellow">
                      <Link to="/kiln-and-heating">
                        <span className="box">3</span>
                        <span>Kiln and Heating</span>
                      </Link>
                    </Card>
                    <Card body className="red">
                      <Link to="/wear-resistant-solutions">
                        <span className="box">4</span>
                      </Link>
                      <Link to="/wear-resistant-solutions">
                        <span>Abrasion Resistant Ceramic Wear Tile</span>
                      </Link>
                    </Card>
                  </CardGroup>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Row className="py-5">
            <Col md>
              <h4>About CerCo</h4>
              <p>
                CerCo Corporation Specialty Ceramics Division manufactures and
                markets ceramic armor, grinding media and mill linings, kiln
                furniture systems, wear-resistant ceramic solutions and advanced
                structural components.
              </p>
            </Col>
            <Col md>
              <h4>Our Commitment</h4>
              <p>
                Quality and service are what customers expect of us and what we
                expect of ourselves. Our promise is to consistently furnish
                products that meet or exceed your expectations. We promise that
                our quest for quality performance will be continuous. We
                guarantee we will continue to expand our range of services, the
                competency of our employees, and the courteous, professional
                treatment you deserve as a valued customer. We appreciate the
                opportunity to show you this promise at work. Thank you for your
                business.
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )}
  />
);

export default IndexPage;
